<script>
/**
 * Nav-bar Component
 */

// import Breadcumb from "../components/widgets/breadcumb";
import moment from 'moment'
import newFolder from "../components/widgets/newFolder.vue";
import NewFile from "../components/widgets/newFile.vue";
import Profile from "../components/widgets/profile.vue";
import Password from "../components/widgets/editPassword.vue";
import EditProfile from "../components/widgets/editProfile.vue";
import ModalSwitchDomain from "../components/widgets/modalSwitchSubdomain.vue";
import DeleteAccount from "./deleteAccount.vue"
import SidebarRight from "./side-bar-right.vue"
import ModalEnterpriseInfo from "../router/views/enterprise/modalEnterpriseInfo.vue"
import firebase from "firebase/app";
import "firebase/messaging";

import Storage from "../api/storage";

export default {
  components: {newFolder,
  // NewFile,
  // Breadcumb,
  SidebarRight,
  ModalEnterpriseInfo,
  ModalSwitchDomain,
  Profile,Password,EditProfile,DeleteAccount},
  data() {
    return {
      keyword: "",
      showDismissibleAlert : true,
      landing : process.env.VUE_APP_LANDING,
      filetype: ".txt",
      listNotifChat: [],
      sa: 5368709120,
      su: 5368709120,
      isDragStart: false,
      isAdminEnterprise: true,
      slideEntreprise: 0,
      // listTitleEnterprise: [],
      listTitleEnterprise: [
        {
          id: 1,
          title: "JAYBO1"
        },
        {
          id: 2,
          title: "JAYB2"
        },
        {
          id: 3,
          title: "JAY3"
        },
        {
          id: 4,
          title: "JA4"
        },
        {
          id: 5,
          title: "J5"
        },
        {
          id: 6,
          title: "JA6"
        },
        {
          id: 7,
          title: "JAY7"
        },
        {
          id: 8,
          title: "JAYB8"
        },
        {
          id: 9,
          title: "JAYBO9"
        },
      ],
      isSubdomainEnterprise: false,
      switchDomain: null
    }
  },
  computed: {
    isWorkgroupSuspend() {
      return this.$store.getters['workgroup/statusSuspendWorkgroup']
    },
    isSuspend() {
      return this.$store.getters['user/accountSuspend']
    },
    currentSubdomainName(){
      var subdomain = window.location.host.split('.')
      return subdomain[0]
      // return 'fafafufu'
      // return 'jayent-mydata'
      // return 'eventmyeve'
      // return 'yangunik'
    },
    listEnterprise(){
      return this.$store.getters["user/listEnterpriseUser"]
    },
    myEnterprise(){
      return this.$store.getters["enterprise/myEnterprise"]
    },
    isApplePay() {
      return this.$store.getters["user/isApplePay"]
    },
    currentRouteName () {
      return this.$route.name
    },
    currentKeyword(){
      return this.$store.getters[this.$store.getters['state/state']+'/keyword']
    },
    originalSize(){
      return this.$store.getters['user/size_directory'];
    },
    originalUsed(){
      return this.$store.getters['user/total_used'];
    },
    statusStorage(){
      var sizeDirectory = this.$store.getters['user/size_directory']
      var usedDirectory = this.$store.getters['user/total_used']
      var total = sizeDirectory - usedDirectory
      var sttStorage
      if (total <= 0){
        sttStorage = 'full'
      } else if (total <= 104857600) {
        sttStorage = 'almost full'
      } else {
        sttStorage = 'almost full'
      }
      return sttStorage
    },
    notification() {
      // return []
      return this.$store.getters['notif/notifs']
    },
    notificationRead() {
      return this.$store.getters['notif/readNotifs']
    },
    // notification2() {
    //   var notif = this.$store.getters['chat/notifChatAndEncrypt']
    //   var newNotif = notif.sort(function(a,b){return b.time - a.time})
    //   return newNotif
    // },
    listingsShare: {
      //getter
      get: function () {
        let data = this.$store.getters['share/items']
        return data
      },
      //setter
      set: function (newValue) {
        this.$store.commit("share/SET_ITEMS",newValue)
      }
    },
    listings: {
      // getter
      get: function () {
        var data = this.$store.getters[this.$store.getters['state/state']+'/recentList'];
        return data.filter(v=>!v.isFolder)
      },
      // setter
      set: function (newValue) {
        this.$store.commit(this.$store.getters['state/state']+"/SET_RECENTLIST",newValue);
      }
    },
    nama() {
          return this.$store.getters['user/fullname']
    },
    message() {
          return this.$store.getters['error/message']
    },
    success() {
          return this.$store.getters['error/success']
    },
    error() {
          return this.$store.getters['error/error']
    },
    showBreadCumb() {
          return this.$store.getters['storage/showBreadcumb']
    },
    subTitle() {
          return this.$store.getters['storage/subTitle']
    },
    selectedField() {
          return this.$store.getters['storage/selected']
    },
    folderOnSelected(){
      return this.selectedField.filter((v)=>v.isFolder);
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
    state(){
        return this.$store.getters['state/state'];
    },
    selectedWorkgroup(){
        return this.$store.getters['workgroup/workgroup'];
    },
    workgroupList(){
      return this.$store.getters['workgroup/workgroups'];
    },
    showDetails(){
        return this.$store.getters['workgroup/showParticipants'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'];
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    }
  },
  // watch: {
  //   keyword(){
  //     this.search()
  //   }
  // },
  beforeMount(){
    this.$store.dispatch('notif/getAllNotification')
  },
  mounted(){
    const navbarElement = document.getElementById("nav-bar-revamp");
    const sidebarRightElement = document.getElementById("side-bar-right")
    const computedHeight = window.getComputedStyle(navbarElement).getPropertyValue("height");
    sidebarRightElement.style.height = `calc(100vh - ${computedHeight})`
    var heightSibarRight = window.getComputedStyle(sidebarRightElement).getPropertyValue("height")
    const root = document.documentElement
    root.style.setProperty('--height-sidebar-right', heightSibarRight)
    root.style.setProperty('--height-navbar-revamp', computedHeight)


    this.checkSubdomain()
    this.$store.getters['storage/notifToggle']
    this.$store.dispatch('storage/isStorageFull')
    this.$store.dispatch('user/getListEnterpriseUser')
    this.key()

    const navEnterprise = document.querySelector(".nav-enterprise-wrapper")
    const draggingNavEnterpriseStart = () => {
      this.isDragStart =  true
    }
    const draggingNavEnterpriseStop = () => {
      this.isDragStart = false
    }
    const draggingNavEnterprise = (e) => {
      if(this.isDragStart == false) return
      e.preventDefault()
      navEnterprise.scrollLeft = e.pageX
    }
    navEnterprise.addEventListener('mousedown', draggingNavEnterpriseStart)
    navEnterprise.addEventListener('mouseup', draggingNavEnterpriseStop)
    navEnterprise.addEventListener('mousemove', draggingNavEnterprise)

    // if(this.selectedWorkgroup != null && this.state == 'workgroup'){
    //   this.$store.dispatch('chat/openChat')
    //   this.$store.getters['chat/sortParticipant']
    // }
    // document.getElementById("uploadLoading").style.display = "block";
    //   var filesData = {
    //     id : "tpm.png359467",
    //     name : "Folder A",
    //     done : true,
    //     status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //     size : 359467,
    //     type : "image/png",
    //     percentage : 50,
    //     error : "",
    //     isFolder : true,
    //     total : 1,
    //     uploaded : 0
    //   }
    //   this.$store.commit("upload/ADD_FILES",filesData);
  },
  created() {
    if(this.$route.query.wgId){
      this.$store.commit('state/SET_STATE', 'workgroup')
      // if(this.selectedWorkgroup == null){

        this.$store.dispatch('workgroup/retrieve', this.$route.query.wgId)
        .then(() => {
          const navbarElement = document.getElementById("nav-bar-revamp");
          const sidebarRightElement = document.getElementById("side-bar-right")
          const computedHeight = window.getComputedStyle(navbarElement).getPropertyValue("height");
          if(sidebarRightElement){
            sidebarRightElement.style.height = `calc(100vh - ${computedHeight})`
          }
          var heightSibarRight = window.getComputedStyle(sidebarRightElement).getPropertyValue("height")
          const root = document.documentElement
          root.style.setProperty('--height-sidebar-right', heightSibarRight)
          root.style.setProperty('--height-navbar-revamp', computedHeight)

          let links = document.getElementsByClassName("nav-bar-link-ref");
          let matchingMenuItem = null;
          for (let j = 0; j < links.length; j++) {
            if (window.location.pathname === links[j].pathname) {
              
              matchingMenuItem = links[j];
                  break;
              }
          }
          matchingMenuItem.classList.add('active')
        })
      // }
    }
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.$store.dispatch('firebase/storeFirebase', token)
              // this.receiveMessage()
              // var firefoxAgent = this.$browserDetect.isFirefox
              // var safariAgent = this.$browserDetect.isSafari
              // var operaAgent = this.$browserDetect.isOpera
              // if(!firefoxAgent && !safariAgent && !operaAgent){
              //   this.receiveMessage();
              // }
            });
        })
        .catch((err) => {
        });
  },
  methods: {
    goToWorkgroup(id){
      this.$store.dispatch("workgroup/retrieve",id)
      .then(()=>{
        if(this.$router.currentRoute.name != "home"){
          this.$router.push({name:"home", query: {wgId: id}}).catch(()=>{});
        } else {
          const currentPath = this.$route.path

          this.$router.push({ path: currentPath, query: {wgId: id}})
          .catch(err => {
            // Handle the navigation error (redundant navigation)
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
            // Optionally, you can perform additional actions if it's a redundant navigation
            // console.log('Redundant navigation prevented.');
          })
        }
        this.$forceUpdate();
        this.$store.dispatch("workgroup/showDetails")
        .then(()=>{
          this.$ga.event('workgroup', 'actionx', 'labelx', 1)
          this.$store.commit(this.state+"/SET_KEYWORD","");
          this.$store.commit(this.state+"/SET_PARENT_ID","");
          this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
          this.$store.commit('storage/SET_ITEM', null)
          this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true)

        })
      });
    },
    toHome(){
      if(this.$route.query.wgId || this.state == 'workgroup'){
        return {
          path: '/',
          query: { wgId: this.selectedWorkgroup._id }
        }
      } else {
        return '/'
      }
    },
    toRecent(){
      if(this.$route.query.wgId || this.state == 'workgroup'){
        return {
          path: '/recent',
          query: { wgId: this.selectedWorkgroup._id }
        }
      } else {
        return '/recent'
      }
    },
    toChat(){
      const workgroupValue = this.selectedWorkgroup
      localStorage.setItem('workgroup', JSON.stringify(workgroupValue))

      return {
        path: '/chat',
        query: { wgId: this.selectedWorkgroup._id }
      }
    },
    toParticipantsWg(){
      return {
        path: '/workgroup/participants',
        query: { wgId: this.selectedWorkgroup._id }
      }
    },
    toWorkgroupSetting(){
      return {
        path: '/workgroup/settings',
        query: { wgId: this.selectedWorkgroup._id }
      }
    },
    checkSubdomain(){
      var subdomain = window.location.host.split('.')
      var nameSubdomain = subdomain[0]
      // var nameSubdomain = 'jayent-mydata'
      // var nameSubdomain = 'fafafufu'
      // var nameSubdomain = 'eventmyeve'
      // var nameSubdomain = 'yangunik'

      if(nameSubdomain == process.env.VUE_APP_X_DOMAIN){
        this.isSubdomainEnterprise = false
      } else {
        this.isSubdomainEnterprise = true
      }
    },
    backToPrimary(){
      window.open(`${process.env.VUE_APP_MAIN_DOMAIN}`, '_blank')
    },
    openEnterprise(data){
      // var payload = {
      //   enterprise: data.id,
      //   link: data.link
      // }
      // this.$store.dispatch('enterprise/getToken', payload)
      this.$store.dispatch('enterprise/getSwitchSubdomain', data)
      .then(()=>{
        this.$bvModal.show('modal-switch-domain')
      })
    },
    slideLeft(){
      var slide = document.getElementById("slide-title-enterprise")
      // var sumEnterprise = (this.listTitleEnterprise.length - 4) * -101
      var sumEnterprise = (this.listEnterprise.length - 1) * -101
      if(this.slideEntreprise < 0){
        this.slideEntreprise = this.slideEntreprise + 101
        slide.style.left = this.slideEntreprise + "px"
      }
    },
    slideRight(){
      var slide = document.getElementById("slide-title-enterprise")
      // var sumEnterprise = (this.listTitleEnterprise.length - 4) * -101
      var sumEnterprise = (this.listEnterprise.length - 1) * -101
      if(sumEnterprise < this.slideEntreprise){
        this.slideEntreprise = this.slideEntreprise - 101
        slide.style.left = this.slideEntreprise + "px"
      }
    },
    infoEnterprise(){
      var list = this.listEnterprise
      var subdomain = window.location.host.split('.')
      var nameSubdomain = subdomain[0]
      // var nameSubdomain = 'fafafufu'
      // var nameSubdomain = 'jayent-mydata'
      // var nameSubdomain = 'eventmyeve'
      // var nameSubdomain = 'yangunik'
      var getEnterprise = list.filter((enterprise) => {
        return enterprise.subdomain == nameSubdomain
      })
      this.$store.dispatch('enterprise/getEnterpriseInfo', getEnterprise[0].id)
      .then(()=> {
        this.$bvModal.show('modal-enterprise-info')
      })
    },
    getPackages(){
      if(this.currentSubdomainName == process.env.VUE_APP_X_DOMAIN){
        if(this.isApplePay == false){
          this.$bvModal.show('modalPackages')
        } else {
          this.$bvModal.show('modal-apple-pay')
        }
      }
    },
    readAllNotif(){
      let allNotifs = this.notification.map((notif)=>{
        return this.$store.dispatch("notif/readNotif", notif)
      })
    },
    readNotif(item){
      this.$store.dispatch("notif/readNotif",item)
    },
    joinWg(item){
      var payload = {
        user_id: item.user_id,
        workgroup_id: item.workgroup_id
      }
      this.$store.dispatch('workgroup/joinWg', payload)
      .then((result)=>{
        this.$store.dispatch("workgroup/retrieve",result._id)
        .then(()=>{
          this.$router.push({ path: '/', query: {wgId: result._id}})
          this.$forceUpdate();
          this.$store.dispatch("workgroup/showDetails");
        })
      })
      
      if(item.is_read == false){
        this.$store.dispatch("notif/readNotif",item)
      }
    },
    key(){
      this.keyword = this.currentKeyword
    },
    upgradePlan(){
      this.$router.push('/packages')
    },
    closeNotifAlert(){
      var notifAlert = document.getElementById('alert-storage-v3')
      // var mainContent = document.getElementById('content-pages')
      notifAlert.style.display = 'none'
      // mainContent.style.marginTop = '0px'
    },
    openParent(item){
      // this.$store.commit('chat/SET_NEW_NOTIF_ENCRYPT', list)
      this.$store.dispatch("notif/readNotif",item);
    },
    getImage(filename){
      var storageUtils = new Storage();
      return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
    },
    receiveMessage() {
        // firebase.messaging().onMessage((payload) => {
        //   var data = payload.data
        //   var newData = JSON.parse(data.data)
        //   // if(newData.datachat != null){
        //   //   this.$store.dispatch('chat/sendFileToChatFirebase', newData)
        //   // }
        //   // this.$store.dispatch('firebase/setNotifFirebase', payload.data)
        //   this.$store.dispatch('firebase/setNotifFirebase', newData)
        //   this.$store.dispatch(this.state+"/listing")
        // });
    },
    sortNotification(){
      var notifMsg = this.$store.getters['chat/notif']
      var notifAllMsg = notifMsg.sort(function(a,b){return b.time - a.time})

      this.$store.dispatch('chat/setNotifChatGroup', notifAllMsg)
      var chatNotif = this.$store.getters["chat/notifChatGroup"]
      this.notification = chatNotif
    },
    openWorkgroup(item){
      // this.$store.dispatch("notif/readNotif",item)
      // .then(()=>{
        this.$ga.event('workgroup', 'actionx', 'labelx', 1)
        this.$store.commit(this.state+"/SET_KEYWORD","");
        this.$store.commit(this.state+"/SET_PARENT_ID","");
        this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
        this.$store.dispatch("workgroup/retrieve",item.group_by)
        .then(()=>{
          if(this.$router.currentRoute.name != "chat"){
            this.$router.push({name:"chat", query: {wgid: item.group_by}});
          }
          this.$store.dispatch('chat/openChat')
          this.$store.dispatch("notif/readNotif",item)
          this.$forceUpdate();
          this.$store.dispatch("workgroup/showDetails");
        });
        // var dropdownWG = document.querySelector('.side-menu-wg-open')
        // dropdownWG.classList.remove('side-menu-wg-open')
      // })
    },
    dateNotif(date){
      return moment(date * 1000).fromNow()
    },
    downloadMultiple(){
      var onlyFiles = this.selectedField.filter((v)=>!v.isFolder);
      var data = {
          directory : this.$store.getters['user/directory'],
          list_id : onlyFiles.map((v)=>{
            return {id:v._id}
          })
      }
      this.$store.dispatch("storage/multipleDownload",data);

    },
    deleteMultiple(){
        this.$root.$emit('bv::show::modal', 'modaltoTrash');
    },
    move(){
        this.$root.$emit('bv::show::modal', 'modalMove');
    },
    copy(){
        this.$root.$emit('bv::show::modal', 'modalCopy');
    },
    changeView(){
        this.$store.commit("storage/SET_SHOW_GRID",!this.grid);
    },
    closeAlertE(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_ERROR",false);
    },
    closeAlertS(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_SUCCESS",false);
    },
    sliceChar(str){
      return str.length > 16 ? str.substring(0,16)+" ..." : str;
    },
    refresh(){
      if(this.subTitle == "Recent Files"){
        this.$store.dispatch(this.state+"/recent")
      }else if(this.subTitle == "Shared"){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$store.dispatch("share/listing")
      }
      else{
        this.$store.dispatch(this.state+"/listing",this.showBreadCumb ? "" : '1')
      }
    },
    search(){
      if(this.$route.path != '/search'){
        this.$router.push('/search')
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.dispatch(this.state+"/listing")
      } else {
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.dispatch(this.state+"/listing")
      }
    },
    searchEnter(){
      if(this.$route.path != '/search'){
        this.$router.push('/search')
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.commit(this.state+"/SET_PARENT_ID","")
        this.$store.dispatch(this.state+"/listing")
      } else {
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.commit(this.state+"/SET_PARENT_ID","")
        this.$store.dispatch(this.state+"/listing")
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    createFile(type){
      this.filetype = type;
      this.$root.$emit('bv::show::modal', 'modalCreate');
    },
    uploadFile(){
      document.getElementById("fileUpload").click()
    },
    uploadFolder(){
      document.getElementById("folderUpload").click()
    },
    checkNested(list){
      return new Promise((resolve,reject)=>{
        var haveNested = false;
        for (let i = 0; i < list.length; i++) {
            haveNested = list[i].webkitRelativePath.split("/").length > 2
        }
        if(haveNested){
          reject(haveNested);
        }else{
          resolve(haveNested);
        }
      });
    },
    // chooseFolder(){
    //   let files = document.getElementById("folderUpload").files;

    //   if(files.length <= 10){

    //   this.checkNested(files)
    //     .then(() => {
    //       var self = this;
    //     document.getElementById("uploadLoading").style.display = "block";
    //     let promise = [];
    //     var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : files[0].webkitRelativePath.split("/")[0],
    //       done : false,
    //       status : 2,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     for (let f = 0; f < files.length; f++) {
    //       if(files[f].size > 0){
    //         var metadata = {
    //               filename: files[f].name,
    //               filetype: files[f].name.split('.').pop(),
    //               directory: self.$store.getters["user/directory"],
    //               path: files[f].webkitRelativePath,
    //               type: "2"
    //           }
    //         promise.push(
    //           this.$store.dispatch(this.state+"/uploadTus",{
    //             file : files[f],
    //             metadata : metadata,
    //             filesData : filesData,
    //             isFolder : true
    //           })
    //         );
    //       }
    //     }
    //     Promise.all(promise).then((data)=>{
    //       var paramUpload = {
    //         parent_id : this.$store.getters[this.state+"/parent_id"],
    //         data : data
    //       }
    //       if(this.state == "workgroup"){
    //         paramUpload = {
    //           workgroup_id : this.selectedWorkgroup._id,
    //           owner_id : this.selectedWorkgroup.owner_id,
    //           parent_id : this.$store.getters[this.state+"/parent_id"],
    //           data : data
    //         }
    //       }
    //       this.$store.dispatch(this.state+"/uploadFolder",paramUpload).then((success)=>{
    //         this.$store.commit("upload/STATUS",{id:filesData.id,status:3,error:success});
    //         this.$store.commit("upload/DONE",{id:filesData.id,done:true});
    //       }).catch((error)=>{
    //         this.$store.commit("upload/STATUS",{id:filesData.id,status:4,error:error});
    //       })
    //     });
    //     })

    //     .catch(() => {
    //       var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : 'Test',
    //       done : false,
    //       status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "Cannot upload nested folder",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     document.getElementById("uploadLoading").style.display = "block";
    //     })
    //   }
    //   else{
    //     var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : 'Test',
    //       done : false,
    //       status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "Cannot upload more than 10 files",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     document.getElementById("uploadLoading").style.display = "block";
    //   }
    // },
    // choosFile(){
    //     let files = document.getElementById("fileUpload").files;
    //     document.getElementById("uploadLoading").style.display = "block";
    //     let promise = [];
    //     for (let i = 0; i < files.length; i++) {
    //       if(files.length <= 10){
    //         if(files[i].size <= 0){
    //           var filesData = {
    //             id : (Math.random() + 1).toString(36).substring(2),
    //             name : files[i].name,
    //             done : true,
    //             status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //             size : files[i].size,
    //             type : files[i].type,
    //             percentage : 0,
    //             error : "File not contain any data, Please create file on jaybod!",
    //             isFolder : false,
    //             total : 1,
    //             uploaded : 0
    //           }
    //           this.$store.commit("upload/ADD_FILES",filesData);
    //         }else{
    //           filesData = {
    //             id : (Math.random() + 1).toString(36).substring(2),
    //             name : files[i].name,
    //             done : false,
    //             status : 2,//1=prepare,2=uploading,3=done,4=failed,
    //             size : files[i].size,
    //             type : files[i].type,
    //             percentage : 0,
    //             error : "",
    //             isFolder : false,
    //             total : 1,
    //             uploaded : 0
    //           }
    //           this.$store.commit("upload/ADD_FILES",filesData);
    //           var metadata = {
    //                 filename: files[i].name,
    //                 filetype: files[i].name.split('.').pop()
    //           }
    //           promise.push(
    //             this.$store.dispatch(this.state+"/uploadTus",{
    //               file : files[i],
    //               metadata : metadata,
    //               filesData : filesData,
    //               isFolder : false
    //             })
    //           );
    //         } 
    //       }else{
    //         this.$store.commit("error/SET_MESSAGE","Cannot upload more than 10 files");
    //         this.$store.commit("error/SET_ERROR",true);
    //       }
    //     }
    //     Promise.all(promise).then(()=>{
    //     });
    // },
    openDetailWorkgroup(){
      this.$store.dispatch("workgroup/showDetails");
    },
    toggleSwitchShow(){
      var box = document.getElementById('box-notif')
      var show = this.$store.getters['storage/notifToggle']

      if(show){
        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)
      }
    },
    toggleNotification(e){
      var box = document.getElementById('box-notif')
      var show = this.$store.getters['storage/notifToggle']
      var toggle = document.getElementById('notif-navbar-id')

      e.stopPropagation()

      if(show){
        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)
      } else {
        box.style.opacity = '1'
        box.style.maxHeight = '100vh'
        box.style.display = 'block'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", true)
      }
      document.addEventListener('click', (e) => {
        if (!box.contains(e.target) && e.taget != toggle) {
          // Using Opacity and Visibility
          box.style.display = 'none'
          this.$store.commit("storage/SET_NOTIF_TOGGLE", false)
        }
      })
    },
    closeNotif(){
        var box = document.getElementById('box-notif')

        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)

    }
  }
};

</script>

<template>
  <header id="nav-bar-revamp">
    <!-- <input id="fileUpload" type="file" multiple hidden @change="choosFile">
    <input id="folderUpload" type="file" hidden @change="chooseFolder" webkitdirectory mozdirectory> -->
    <!-- <NewFile :filetype="filetype" /> -->
    <Profile/>
    <Password/>
    <EditProfile/>
    <DeleteAccount/>
    <ModalEnterpriseInfo/>
    <ModalSwitchDomain :item="this.switchDomain"/>
    <div id="alert-storage-v3">
      <div class="d-flex justify-content-between align-items-center">
        <span>Your storage is {{statusStorage}}. Get more space by upgrading your plan now. <span>Upgrade plan</span></span>
        <img @click="closeNotifAlert()" class="cursor-pointer" src="@/assets/images/icon/close-btn-white.svg" alt="">
      </div>
    </div>
    <b-modal id="modalContainer" :hide-footer="true" :hide-header="true" centered title="BootstrapVue">
      <newFolder/>
    </b-modal>
    <div class="navbar-header" :class="this.state == 'enterprise' || currentRouteName == 'SettingsOverview' || currentRouteName == 'SettingsPlan' ? 'border-bottom-E9EAEA' : ''">
      <div class="d-flex">
        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item d-lg-none"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
      <div class="w-100" >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div v-if="this.state == 'enterprise' || currentRouteName == 'SettingsOverview' || currentRouteName == 'SettingsPlan'" class="w-100 pl-4 mt-3">
            <span v-if="currentRouteName == 'SettingsOverview' || currentRouteName == 'SettingsPlan'" class="nav-title-enterprise">{{nama}}</span>
            <span v-else class="nav-title-enterprise">{{myEnterprise ? myEnterprise.company_name : ''}}</span>
          </div>
          <!-- <div v-else class="d-flex flex-fill ml-4 show-desktop"> -->
          <div v-else class="w-100 pl-4 mt-3 show-desktop">
            <span v-if="this.state == 'storage'" class="nav-title-enterprise">Personal</span>
            <span v-if="this.state == 'workgroup'" class="nav-title-enterprise">{{selectedWorkgroup.title}}</span>
            <!-- <form class="pb-0 pt-4 d-none d-lg-block" @submit.prevent="searchEnter">
              <div class="">
                <input
                  id="inputSearch"
                  v-model="keyword"
                  @keyup="keyword.length >= 3 ? search() : ''"
                  class="form-control login-input-style search-navbar-box"
                  placeholder="Search file or folder"
                  />
                <img src="@/assets/images/icon/search-black.svg" alt="" class="search-navbar-icon">
              </div>
            </form> -->
          </div>
          <div class="d-flex align-items-center justify-content-end w-100">
            <div class="hide-desktop d-flex justify-content-beetwen w-100">
              <!-- <form class="app-search-sm pb-0 pt-4" @submit.prevent="searchEnter">
                <div class="position-relative">
                  <input type="search"
                    v-model="keyword"
                    @keyup="keyword.length >= 3 ? search() : ''"
                    class="form-control login-input-style" 
                    placeholder="Search file or folder" />
                  <span class="bx bx-search-alt"></span>
                </div>
              </form> -->
            </div>
            <div class="mt-3 mr-4 d-flex">
              <div class="cursor-pointer" id="left-arrow-enterprise">
                <img v-if="listEnterprise.length > 1" src="@/assets/images/icon/chevRight.svg" alt="" height="100%" class="chev-nav-enterprise" @click="slideLeft()">
              </div>
              <div class="container-nav-enterprise">
                <div class="nav-enterprise-wrapper" id="slide-title-enterprise">
                  <div v-if="listEnterprise.length > 0 && myEnterprise != null" class="list-nav-enterprise">
                    <div @click="backToPrimary()" class="d-flex align-items-center" :class="currentSubdomainName != 'jayent-mydata' && currentSubdomainName != 'mydata' ? 'op-6' : ''">
                    <!-- <div @click="openEnterprise(null)" class="d-flex align-items-center" :class="currentSubdomainName != 'jayent-mydata' && currentSubdomainName != 'mydata' ? 'op-6' : ''"> -->
                      <img src="@/assets/images/icon/icon-primary.svg" alt="" height="100%">
                      <span class="">Primary</span>
                    </div>
                  </div>
                  <div class="list-nav-enterprise" v-for="(enterprise, idx) in listEnterprise" :key="idx">
                    <div @click="openEnterprise(enterprise)" class="d-flex align-items-center" :class="currentSubdomainName != enterprise.subdomain ? 'op-6' : ''">
                      <img src="@/assets/images/icon/icon-enterprise.svg" alt="" height="100%">
                      <span class="">{{enterprise.short_name}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cursor-pointer" id="right-arrow-enterprise">
                <img v-if="listEnterprise.length > 1" src="@/assets/images/icon/chevLeft.svg" alt="" height="100%" class="chev-nav-enterprise" @click="slideRight()">
              </div>
            </div>
            <div class="mt-3 mx-3">
              <div id="notif-navbar-id" class="notif-navbar" @click="toggleNotification($event)">
                <div v-if="notification.length <= 0">
                  <img src="@/assets/images/icon/s-lonceng.svg" alt="">
                </div>
                <div v-else>
                  <img src="@/assets/images/icon/s-lonceng.svg" alt="">
                  <!-- <span class="notif-badge">99+</span> -->
                  <span class="notif-badge">{{notification.length}}</span>
                </div>
              </div>
              <div class="notif-box" id="box-notif">
                <div class="notif-header">
                  <div class="notif-header-right">
                    <span>Notification</span>
                    <!-- <span v-if="notification.length > 0"><span>{{notification.length}}</span></span> -->
                  </div>
                  <!-- <div v-if="notification.length <= 0" class="notif-header-right">
                    <span>Notification</span>
                  </div> -->
                  <!-- <div v-else class="notif-header-right">
                    <span>Notification</span>
                    <span><span>{{notification.length}}</span></span>
                  </div> -->
                  <div>
                    <span @click="readAllNotif()" class="mark-as-read mr-3">Mark all as read</span>
                    <img class="cursor-pointer" data-toggle="tooltip" title="Close" @click="closeNotif" src="@/assets/images/icon/close-svg.svg" alt="">
                  </div>
                </div>
                <b-tabs class="tabs-notification" active-nav-item-class="active-tabs">
                  <b-tab title="Unread">
                    <div class="notif-body">
                      <div v-if="notification.length <= 0" class="notif-empty">
                        <img src="@/assets/images/notification-unread.svg" alt="">
                        <div class="notif-empty-text">
                          <p>All your unread notifications will show up here.</p>
                        </div>
                      </div>
                      <div v-else v-for="(notif, index) in notification" :key="index">
                        <div v-if="notif.group_by == null">
                          <div @click="openParent(notif)" class="notif-info bg-new-notification">
                            <!-- <pre>{{notif}}</pre> -->
                            <div class="notif-icon">
                              <!-- <img :src="require(`@/assets/images/icon/${notif.icon}`)" alt height="60%" /> -->
                              <img v-if="notif.encrypt == 2 || notif.encrypt == 5 || notif.encrypt.code == 2 || notif.encrypt.code == 5" src="@/assets/images/icon/secure-success.svg" alt/>
                              <img v-else src="@/assets/images/icon/secure-failed.svg" alt/>
                            </div>
                            <div class="notif-text">
                              <span class="file-name-notif mb-2">{{ notif.title }}</span>
                              <span class="messages-notif mb-3">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'File shared'">
                          <div @click="openParent(notif)" class="notif-info bg-new-notification">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{notif}}</pre> -->
                              <span class="file-name-notif mb-2">{{notif.title}}</span>
                              <span class="file-name-notif mb-2">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                              <!-- <button class="notif-btn-join mt-3 notification-btn">
                                View details
                              </button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'workgroup'">
                          <div @click="openParent(notif)" class="notif-info bg-new-notification">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{notif}}</pre> -->
                              <span class="file-name-notif mb-2">{{notif.title}}</span>
                              <span class="file-name-notif mb-2">{{notif.message}} Please check your email to join.</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                              <!-- <button @click="joinWg(notif)" class="notif-btn-join mt-3 notification-btn">
                                Join now
                              </button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'workgroup_info'">
                          <div @click="readNotif(notif)" class="notif-info bg-new-notification">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <span class="file-name-notif mb-2">{{ notif.title }}</span>
                              <span class="messages-notif mb-3">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <!-- <div @click="openWorkgroup(notif)" class="notif-info bg-new-notification"> -->
                          <div @click="openParent(notif)" class="notif-info bg-new-notification">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{ notif }}</pre> -->
                              <!-- blm ada notif notif.title nya -->
                              <!-- <span class="file-name-notif mb-2">{{ notif.title }}</span> -->
                              <!-- notif.title adalah notif.message -->
                              <span class="file-name-notif mb-3">{{notif.title}}<span class="text-blue" @click="goToWorkgroup(notif.group_by)">{{notif.group_name}}</span></span>
                              <div class="messages-notif-info d-flex justify-content-end">
                                <!-- notif.message masih menunjukkan waktu -->
                                <span class="messages-notif-info">{{notif.message}}</span> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Read">
                    <div class="notif-body">
                      <div v-if="notificationRead.length <= 0" class="notif-empty">
                        <img src="@/assets/images/notification-read.svg" alt="">
                        <div class="notif-empty-text">
                          <p>All your read notifications will show up here.</p>
                        </div>
                      </div>
                      <div v-else v-for="(notif, index) in notificationRead" :key="index">
                        <!-- <span>{{notificationRead.length}}</span> -->
                        <div v-if="notif.group_by == null">
                          <!-- <div @click="openParent(notif)" class="notif-info bg-new-notification"> -->
                          <div class="notif-info crsr-none">
                            <div class="notif-icon">
                              <!-- <img :src="require(`@/assets/images/icon/${notif.icon}`)" alt height="60%" /> -->
                              <img v-if="notif.encrypt == 2 || notif.encrypt == 5 || notif.encrypt.code == 2 || notif.encrypt.code == 5" src="@/assets/images/icon/secure-success.svg" alt/>
                              <img v-else src="@/assets/images/icon/secure-failed.svg" alt/>
                            </div>
                            <div class="notif-text">
                              <span class="file-name-notif mb-2">{{ notif.title }}</span>
                              <span class="messages-notif mb-3">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup ? notif.workgroup : ''}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'File shared'">
                          <div class="notif-info crsr-none">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{notif}}</pre> -->
                              <span class="file-name-notif mb-2">{{notif.title}}</span>
                              <span class="file-name-notif mb-2">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                              <!-- <button class="notif-btn-join mt-3 notification-btn">
                                View details
                              </button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'workgroup'">
                          <div class="notif-info crsr-none">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{notif}}</pre> -->
                              <span class="file-name-notif mb-2">{{notif.title}}</span>
                              <span class="file-name-notif mb-2">{{notif.message}} Please check your email to join.</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                              <!-- <button @click="joinWg(notif)" class="notif-btn-join mt-3 notification-btn">
                                Join now
                              </button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else-if="notif.group_by == 'workgroup_info'">
                          <!-- <div @click="readNotif(notif)" class="notif-info bg-new-notification"> -->
                          <div class="notif-info crsr-none">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <span class="file-name-notif mb-2">{{ notif.title }}</span>
                              <span class="messages-notif mb-3">{{notif.message}}</span>
                              <div class="messages-notif-info">
                                <span>{{notif.enterprise ? notif.enterprise : ''}}<span v-if="notif.enterprise" class="notif-dot">•</span>{{notif.workgroup ? notif.workgroup : ''}}</span>
                                <span>{{notif.time}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Notif message -->
                        <div v-else>
                          <div class="notif-info crsr-none">
                            <div class="notif-icon">
                              <img src="@/assets/images/icon/icon-info-notif.svg" alt="">
                            </div>
                            <div class="notif-text">
                              <!-- <pre>{{ notif }}</pre> -->
                              <!-- blm ada notif notif.title nya -->
                              <!-- <span class="file-name-notif mb-2">{{ notif.title }}</span> -->
                              <!-- notif.title adalah notif.message -->
                              <span class="file-name-notif mb-3">{{notif.title}}<span>{{notif.group_name}}</span></span>
                              <div class="messages-notif-info d-flex justify-content-end">
                                <!-- notif.message masih menunjukkan waktu -->
                                <span class="messages-notif-info">{{notif.message}}</span> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
                <span v-if="notification.length > 0" class="unread-notif">{{notification.length}}</span>
              </div>
            </div>
            <div class="mr-3 mt-3">
              <b-dropdown id="dropdown-dropup" dropup right class="bg-transparent profile-dropdown">
                <template #button-content>
                    <div @click="toggleSwitchShow" class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{nama.charAt(0).toUpperCase()}}</span>
                    </div>
                </template>
                <b-dropdown-header id="dropdown-header-label">
                  <div class="d-flex">
                    <b-avatar :text="nama.charAt(0).toUpperCase()" class="mr-2 avatar-xs main-btn-api"></b-avatar>
                    <div class="d-flex justify-content-start align-items-center">
                      <span class="user-name enterprise-text-desc">{{nama}}</span>
                    </div>
                  </div>
                </b-dropdown-header>
                <b-dropdown-item v-if="isSubdomainEnterprise == false" href="/settings/overview">
                  <img src="@/assets/images/icon/setting.svg" alt height="100%" />
                  <span class="ml-3 enterprise-text-desc">Settings</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item v-if="isSubdomainEnterprise == false" @click="getPackages()">
                  <img src="@/assets/images/icon/upgrade.svg" alt height="100%" />
                  <span class="ml-3 enterprise-text-desc">Upgrade</span>
                </b-dropdown-item> -->
                <b-dropdown-item v-if="isSubdomainEnterprise == true" @click="isSuspend != true ? infoEnterprise() : ''">
                  <img src="@/assets/images/icon/fi-rr-info.svg" alt height="100%" />
                  <span class="ml-3 enterprise-text-desc">Enterprise Info</span>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="/logout">
                  <img src="@/assets/images/icon/logout.svg" alt height="100%" />
                  <span class="ml-3 enterprise-text-desc">Sign Out</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="mx-3" id="jarak"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentRouteName == 'SettingsPlan' || currentRouteName == 'SettingsOverview'" class="nav-bar-links" style="border-bottom: 1px solid #e9eaea">
      <div class="pl-4 mb-4">
        <span class="title-admin-enterprise">Primary Account</span>
      </div>
      <ul>
        <li>
          <router-link tag="a" to="/settings/overview" class="nav-bar-link-ref">
            <span>Overview</span>
            <span><span></span></span>
          </router-link>
        </li>
        <!-- <li>
          <router-link tag="a" to="/settings/plan" class="nav-bar-link-ref">
            <span>Plan</span>
            <span><span></span></span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div v-else class="nav-bar-links" style="border-bottom: 1px solid #e9eaea">
      <div v-if="this.state == 'enterprise'" class="pl-4 mb-4">
        <span class="title-admin-enterprise">Enterprise Admin</span>
      </div>
      <ul v-if="this.state == 'enterprise'">
        <li>
          <router-link tag="a" to="/enterprise/admin/overview" class="nav-bar-link-ref">
            <span>Overview</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li>
          <router-link tag="a" to="/enterprise/admin/member" class="nav-bar-link-ref">
            <span>Seat</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li>
          <router-link tag="a" to="/enterprise/admin/customize" class="nav-bar-link-ref">
            <span>Customization</span>
            <span><span></span></span>
          </router-link>
        </li>
        <!-- <li>
          <router-link tag="a" to="/enterprise/admin/plan" class="nav-bar-link-ref">
            <span>Plan</span>
            <span><span></span></span>
          </router-link>
        </li> -->
      </ul>
      <ul v-else>
        <li>
          <router-link tag="a" :to="toHome()" class="nav-bar-link-ref">
            <span>{{this.state == 'storage' ? 'My Files' : 'Group Files'}}</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li>
          <router-link tag="a" :to="toRecent()" class="nav-bar-link-ref">
            <span>Recents</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li v-if="this.state == 'storage'">
          <router-link tag="a" to="/share" class="nav-bar-link-ref">
            <span>Shared</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li v-if="this.state == 'storage'">
          <router-link tag="a" to="/trash" class="nav-bar-link-ref">
            <span>Trash</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li v-if="this.state == 'workgroup'">
          <router-link tag="a" :to="toChat()" class="nav-bar-link-ref">
            <span>Chat</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li v-if="this.state == 'workgroup'">
          <router-link tag="a" :to="toParticipantsWg()" class="nav-bar-link-ref">
            <span>Participants</span>
            <span><span></span></span>
          </router-link>
        </li>
        <li v-if="this.state == 'workgroup'">
          <router-link tag="a" :to="toWorkgroupSetting()" class="nav-bar-link-ref">
            <span>Settings</span>
            <span><span></span></span>
          </router-link>
        </li>
      </ul>
    </div>
    <SidebarRight 
      v-if="currentRouteName != 'Setting' && 
      currentRouteName != 'workgroupSetting' && 
      currentRouteName != 'FileShared' &&
      currentRouteName != 'SettingsOverview' &&
      currentRouteName != 'SettingsPlan' &&
      currentRouteName != 'EnterpriseAdminOverview' &&
      currentRouteName != 'EnterpriseAdminMember' &&
      currentRouteName != 'EnterpriseAdminCustomize' &&
      currentRouteName != 'EnterpriseAdminPlan' &&
      currentRouteName != 'WorkgroupSettings' &&
      currentRouteName != 'WorkgroupParticipants' &&
      currentRouteName != 'trash' &&
      (isSuspend != true && state == 'storage') ||
      (isWorkgroupSuspend != true && state == 'workgroup' && currentRouteName != 'WorkgroupSettings' && currentRouteName != 'WorkgroupParticipants')"/>
  </header>
</template>

<style>

.search-navbar-box{
  position: relative;
  width: 35vw;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #919394;
}
.search-navbar-icon{
  position: absolute;
  margin-left: 11px;
  top: 41px;
}

</style>